@import "src/styles/mixins";
@import "src/styles/colors";

.not-found-found-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1d !important;

  .ant-result {
    .anticon-robot {
      color: $primary !important;
    }

    .ant-btn {
      font-weight: 600 !important;
      @include common-button;
    }

    .ant-result-title {
      font-size: 14px !important;
      color: $primary !important;
    }

    .ant-result-subtitle {
      font-size: 12px !important;
      color: $primary !important;
    }
  }
}
