@import "./colors.scss";
@import "./mixins.scss";
@import "./fonts.css";

html {
  -webkit-tap-highlight-color: transparent;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  background: white;
  line-height: 1.42857;
  color: $black;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include PPRadioGrotesk-regular-font();
}

// common class
.full-height {
  @include full-height-container;
}

.ant-input,
.ant-input-affix-wrapper {
  &.transparent-input {
    background-color: $medium-grey !important;
    color: white !important;
    border: 1px solid rgba(102, 112, 133, 1) !important;

    &:disabled {
      background-color: $dark-grey !important;
      color: gray !important;
      border: none !important;
    }

    &::placeholder {
      color: white !important;
    }

    &:focus {
      color: white !important;
      // border: 1px solid $red !important;
    }
  }
}

.transparent-select {
  .ant-select-selector {
    background-color: $medium-grey !important;
    border: 1px solid rgba(102, 112, 133, 1) !important;

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: white !important;
    }
  }

  .ant-select-arrow {
    color: white !important;
  }
}

.ant-select-dropdown {
  background-color: rgba(51, 51, 51, 1) !important;
  color: white !important;

  .ant-empty-description {
    color: white !important;
  }

  .ant-select-item-option-selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .ant-select-item {
    color: white !important;
  }

  .ant-select-item-option-disabled {
    color: grey !important;
  }
}

.custom-radio-bg {
  .ant-radio-button-wrapper {
    color: white !important;
    border: 1px solid $medium-grey !important;
    background-color: transparent !important;

    &::before {
      background-color: transparent !important;
    }

    &:hover {
      color: white !important;
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: $medium-grey !important;
  }
}

.custom-checkbox {
  .ant-checkbox-inner {
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-color: rgba(255, 255, 255, 0.1) !important;

    // &::after {
    //   border: 1px solid $primary;
    // }
  }
}
